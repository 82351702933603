<template>
  <div class="plan-usage" :class="[type]" v-if="userPlan">

    <div class="header columned" v-if="type === 'full'">
      <div>
        <h5 class="mb-2">
          {{ userPlan.name }} Plan
          <span class="ml-2 badge subtle gray" v-if="payPeriod">{{ payPeriod }}</span>
        </h5>
        <div class="helper-text">
          <strong>Current usage period:</strong>
          {{ usageCycleStartsAt }} - {{ usageCycleEndsAt }}
        </div>
      </div>
      <div>
        <router-link class="button secondary small" to="/account/plans">Change plan</router-link>
      </div>
    </div>

    <div class="header" v-if="type === 'compact'">
      <h6>{{ userPlan.name }} Plan</h6>
    </div>

    <div class="gague-container">
      <div class="gague-breakdown">
        <span>Actions</span>
        <span class="flex-grow" />
        <span class="gague-counts">
          <span>{{ formatNumber(userPlanStats.usage) }}</span>
          <span>/</span>
          <span>{{ formatNumber(userPlan.actionEventLimit) }}</span>
          <span>·</span>
          <span :class="{ 'text-danger-700': shouldShowActionLimitErrors }">
            {{ formatNumber(actionsUsedPercentage, 0) }}%
          </span>
        </span>
      </div>
      <Gauge :percentage="actionsUsedPercentage" />
      <div class="usage-reset columned">
        <div>
          <div>
            <strong>Usage reset:</strong>
            {{ usageCycleEndsAt }}
          </div>
          <div class="mt-3" v-if="isPPAEnabled">
            <strong>Overage rate:</strong>
            $0.02 / Action
            <Tooltip class="ml-1">
              <template #trigger>
                <InfoIcon class="info-icon" />
              </template>
              <p>
                Actions over your plan limit will be charged at $0.02 per Action, and billed after your usage cycle ends.
              </p>
              <p>
                <router-link to="/account/plans#pay-per-action">Learn more</router-link>
              </p>
            </Tooltip>
          </div>
        </div>
        <template v-if="type !== 'full'">
          <template v-if="userPlan.slug !== 'pro' || !isPPAEnabled">
            <router-link to="/account/plans" class="button small secondary">
              Upgrade
            </router-link>
          </template>
          <template v-else>
            <Tooltip>
              <template #trigger>
                <button class="small secondary" disabled>
                  Upgrade
                </button>
              </template>
              <p>
                Need more than the Pro Plan provides?
              </p>
              <p>
                <button class="link" @click="openHelpscoutBeacon('Question about Dispatch Enterprise Plan')">Request a call</button>
              </p>
            </Tooltip>
          </template>
        </template>
      </div>
      <div class="alert warning small" v-if="shouldShowActionLimitWarnings && (type === 'full' || userFlags.hasDismissedActionLimitWarningBanner)">
        <p>Your Patches will pause when you hit the limit. Upgrade to make sure you don’t miss any activity.</p>
      </div>
      <div class="alert error small" v-if="shouldShowActionLimitErrors">
        <p>Your Patches are paused. Upgrade or turn on Pay per Action to continue receiving data.</p>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapState, mapGetters } from 'vuex'

  import InfoIcon from '@/assets/icons/info.svg'

  import useFilters from '@/composables/useFilters'

  import Gauge from '@/components/etc/Gauge.vue'
  import Tooltip from '@/components/utils/Tooltip.vue'

  export default {
    inject: [
      '$helpscout',
    ],
    components: {
      Gauge,
      Tooltip,
      InfoIcon,
    },
    props: {
      type: {
        type: String,
        default: 'full',
        validator: (value) => {
          return value === 'full' || value === 'compact'
        },
      },
    },
    setup(props) {

      const { formatNumber } = useFilters()

      return {
        formatNumber,
      }

    },
    computed: {
      ...mapState('user', [
        'user',
        'userFlags',
        'userPlan',
        'userPlanStats',
      ]),
      ...mapGetters('user', [
        'payPeriod',
        'isPPAEnabled',
        'usageCycleEndsAt',
        'usageCycleStartsAt',
        'actionsUsedPercentage',
        'shouldShowActionLimitErrors',
        'shouldShowActionLimitWarnings',
      ]),
    },
    methods: {
      openHelpscoutBeacon(subject) {
        this.$helpscout.onReady((helpscout) => {
          helpscout('prefill', {
            email: this.user.email,
            name: this.user.name,
            subject,
          })
          helpscout('open')
        })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .columned
    @apply flex
    @apply gap-4
    @apply items-start
    @apply justify-between

  .plan-usage
    @apply p-8
    @apply w-full
    @apply border
    @apply rounded
    @apply border-gray-400

    &.compact
      @apply p-6
      @apply border-2

      max-width: theme('width.96')

  .helper-text
    @apply text-sm
    @apply text-gray-600

  .gague-container
    @apply mt-4

    .gague-breakdown
      @apply flex
      @apply font-bold

    .gague-counts
      @apply flex
      @apply gap-1
      @apply flex-wrap

    .usage-reset
      @apply mt-4
      @apply text-sm

    .alert
      @apply mt-4

      max-width: 25rem

  .info-icon
    @apply w-4
    @apply h-4
    @apply inline
    @apply rounded-full
    @apply bg-gray-800
    @apply text-gray-500
    @apply align-text-bottom

</style>
