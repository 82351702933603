<template>
  <div class="gauge">
    <div
      class="fill"
      :style="{ width: `${clampedPercentage}%` }"
      :class="{ 'min': clampedPercentage !== 0 }"
    ></div>
  </div>
</template>

<script>

  export default {
    props: {
      percentage: {
        type: Number,
        required: true,
      },
    },
    computed: {
      clampedPercentage() {
        return Math.min(Math.max(0, this.percentage), 100)
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .gauge
    @apply h-4
    @apply mt-2
    @apply w-full
    @apply bg-gray-300
    @apply rounded-full

  .fill
    @apply w-0
    @apply h-full
    @apply rounded-full

    @apply to-purple-300
    @apply from-primary-500
    @apply bg-gradient-to-r

    max-width: 100%

    &.min
      min-width: 1rem

</style>
